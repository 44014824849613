import './App.css';
import TotalTwist from './Games/Total-Twist/TotalTwist';

function App() {
  return (
    <div className="App">
      <TotalTwist />
    </div>
  );
}

export default App;
