import React, { useEffect, useRef, useState } from 'react';
import { MdKeyboard } from "react-icons/md";
import { IoMusicalNotes } from "react-icons/io5";
import { IoMusicalNotesOutline } from "react-icons/io5";
import themeMusic from '../../Game-Music/private detective music.wav'
import '../Total-Twist/TotalTwist.css';

const keys = [
  { label: 1 },
  { label: 2 },
  { label: 3 },
  { label: 4 },
  { label: 5 },
  { label: 6 },
  { label: 7 },
  { label: 8 },
  { label: 9 },
  { label: 'x' }
];

const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

export default function TotalTwist() {

  const [firstNumber, setFirstNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [secondNumber, setSecondNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [thirdNumber, setThirdNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [fourthNumber, setFourthNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [fifthNumber, setFifthNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [sixthNumber, setSixthNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [seventhNumber, setSeventhNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [eighthNumber, setEighthNumber] = useState(Math.floor(Math.random() * 9) + 1);
  const [ninthNumber, setNinthNumber] = useState(Math.floor(Math.random() * 9) + 1);

  // WINNING OR LOSING STATE
  const [winning, setWinning] = useState(false);



  const [music, setMusic] = useState(false);

  // GLOW EFFECTS HANDLING STATES
  const [RowFirstIsGlow, setRowFirstIsGlow] = useState(false);
  const [RowSecondIsGlow, setRowSecondIsGlow] = useState(false);
  const [RowThirdIsGlow, setRowThirdIsGlow] = useState(false);

  const [ColumnFirstIsGlow, setColumnFirstIsGlow] = useState(false);
  const [ColumnSecondIsGlow, setColumnSecondIsGlow] = useState(false);
  const [ColumnThirdIsGlow, setColumnThirdIsGlow] = useState(false);

  // Timer
  const [timeLeft, setTimeLeft] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false)

  const [focusedBox, setFocusedBox] = useState(null);

  useEffect(() => {
    if (RowFirstIsGlow && RowSecondIsGlow && RowThirdIsGlow && ColumnFirstIsGlow && ColumnSecondIsGlow && ColumnThirdIsGlow) {
      setWinning(true)
    }
  }, [RowFirstIsGlow, RowSecondIsGlow, RowThirdIsGlow, ColumnFirstIsGlow, ColumnSecondIsGlow, ColumnThirdIsGlow])



  // BOX VALUES - USER INPUTS
  const [boxValues, setBoxValues] = useState({
    box1: '',
    box2: '',
    box3: '',
    box4: '',
    box5: '',
    box6: '',
    box7: '',
    box8: '',
    box9: ''
  })

  useEffect(() => {
    function timer() {

      if (winning) {
        setTimeLeft(0);
        setIsTimerRunning(false);
        return;
      }

      if (isTimerRunning && timeLeft > 0) {
        const timerId = setInterval(() => {
          setTimeLeft(prevTime => prevTime - 1)
        }, 1000);
        return () => clearInterval(timerId);
      } else if (timeLeft === 0) {
        setIsTimerRunning(false);
      }
    }
    const cleanup = timer();
    return cleanup;

  }, [isTimerRunning, timeLeft]);

  // VIRTUAL KEYBOARD
  const handleKeyClick = (label) => {
    if (focusedBox !== null) {
      if (label === 'x') {
        setBoxValues((prevValues) => ({
          ...prevValues,
          [focusedBox]: ''
        }));
      } else {
        setBoxValues((prevValues) => ({
          ...prevValues,
          [focusedBox]: Number(label)
        }));
      }
    }
  };

  const handleFocus = (name, index) => {
    setFocusedBox(name);

    if (inputRefs.current[index]) {
      const inputElement = inputRefs.current[index];
      setTimeout(() => {
        inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
      }, 0);
    }
  };

  const audioRef = useRef(new Audio(themeMusic));

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = true;

    if (music) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [music]);

  function handleMusicIcon() {
    setMusic(!music);
  }

  function handleBoxChange(e) {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      const lastDigit = value.slice(-1);
      const parsedValue = parseInt(lastDigit) || '';
      setBoxValues((prevValues) => ({
        ...prevValues,
        [name]: parsedValue
      }));
    }
  }

  function handleWheel(e, index) {
    e.preventDefault();

    if (timeLeft === 60 || timeLeft === 0) {
      e.preventDefault();
      return;
    }
    setBoxValues((prevValues) => {
      let currentValue = prevValues[`box${index + 1}`] || 1; // Default to 1 if the value is empty

      if (e.deltaY < 0) {
        // Scrolling up, increase value
        currentValue = currentValue < 9 ? currentValue + 1 : 1;
      } else {
        // Scrolling down, decrease value
        currentValue = currentValue > 1 ? currentValue - 1 : 9;
      }

      return {
        ...prevValues,
        [`box${index + 1}`]: currentValue
      };
    });
  }

  function handleStart() {
    setTimeLeft(60)
    setIsTimerRunning(!isTimerRunning);
    setBoxValues({
      box1: '',
      box2: '',
      box3: '',
      box4: '',
      box5: '',
      box6: '',
      box7: '',
      box8: '',
      box9: ''
    })
  }

  function handlePlayAgain() {
    setBoxValues({
      box1: '',
      box2: '',
      box3: '',
      box4: '',
      box5: '',
      box6: '',
      box7: '',
      box8: '',
      box9: ''
    })
    setTimeLeft(60)
    setIsTimerRunning(!isTimerRunning)
    if (winning) {
      generateNewPuzzle();
    }
    setWinning(false)
  }


  const firstRowFirstNum = firstNumber + fourthNumber + seventhNumber;
  const firstRowSecondNum = secondNumber + fifthNumber + eighthNumber;
  const firstRowThirdNum = thirdNumber + sixthNumber + ninthNumber;

  const secondRowFirstNum = firstNumber + secondNumber + thirdNumber;
  const thirdRowFirstNum = fourthNumber + fifthNumber + sixthNumber;
  const fourthRowFirstNum = seventhNumber + eighthNumber + ninthNumber;

  function generateNewPuzzle() {
    setFirstNumber(Math.floor(Math.random() * 9) + 1)
    setSecondNumber(Math.floor(Math.random() * 9) + 1)
    setThirdNumber(Math.floor(Math.random() * 9) + 1)
    setFourthNumber(Math.floor(Math.random() * 9) + 1)
    setFifthNumber(Math.floor(Math.random() * 9) + 1)
    setSixthNumber(Math.floor(Math.random() * 9) + 1)
    setSeventhNumber(Math.floor(Math.random() * 9) + 1)
    setEighthNumber(Math.floor(Math.random() * 9) + 1)
    setNinthNumber(Math.floor(Math.random() * 9) + 1)
  }


  // FOR FIRST ROW GLOW (REMEMBER EXCLUDED THE FIRST ONE)
  useEffect(() => {
    const secondRowFirstNumSum = boxValues.box1 + boxValues.box2 + boxValues.box3;
    if (secondRowFirstNumSum === secondRowFirstNum) {
      setRowFirstIsGlow(true)
    } else {
      setRowFirstIsGlow(false)
    }
  }, [boxValues.box1, boxValues.box2, boxValues.box3, secondRowFirstNum]);

  // SECOND ROW GLOW
  useEffect(() => {
    const thirdRowFirstNumSum = boxValues.box4 + boxValues.box5 + boxValues.box6
    if (thirdRowFirstNumSum === thirdRowFirstNum) {
      setRowSecondIsGlow(true);
    } else {
      setRowSecondIsGlow(false);
    }
  }, [boxValues.box4, boxValues.box5, boxValues.box6, thirdRowFirstNum]);

  // THIRD ROW GLOW
  useEffect(() => {
    const fourthRowFirstNumSum = boxValues.box7 + boxValues.box8 + boxValues.box9;
    if (fourthRowFirstNumSum === fourthRowFirstNum) {
      setRowThirdIsGlow(true);
    } else {
      setRowThirdIsGlow(false);
    }
  }, [boxValues.box7, boxValues.box8, boxValues.box9, fourthRowFirstNum]);

  // FIRST COLUMN GLOW EFFECT (SUM)
  useEffect(() => {
    const firstColumnSum = boxValues.box1 + boxValues.box4 + boxValues.box7;
    if (firstColumnSum === firstRowFirstNum) {
      setColumnFirstIsGlow(true);
    } else {
      setColumnFirstIsGlow(false);
    }
  }, [boxValues.box1, boxValues.box4, boxValues.box7, firstRowFirstNum]);

  // SECOND COLUMN GLOW EFFECT
  useEffect(() => {
    const secondColumnSum = boxValues.box2 + boxValues.box5 + boxValues.box8
    if (secondColumnSum === firstRowSecondNum) {
      setColumnSecondIsGlow(true);
    } else {
      setColumnSecondIsGlow(false);
    }
  }, [boxValues.box2, boxValues.box5, boxValues.box8, firstRowSecondNum]);

  // THIRD COLUMN GLOW EFFECT
  useEffect(() => {
    const thirdColumnSum = boxValues.box3 + boxValues.box6 + boxValues.box9
    if (thirdColumnSum === firstRowThirdNum) {
      setColumnThirdIsGlow(true);
    } else {
      setColumnThirdIsGlow(false);
    }
  }, [boxValues.box3, boxValues.box6, boxValues.box9, firstRowThirdNum]);

  const inputRefs = useRef([]);

  const handleKeyDown = (e, index) => {
    switch (e.key) {
      case 'ArrowRight':
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1].focus();
        }
        break;

      case 'ArrowLeft':
        if (inputRefs.current[index - 1]) {
          inputRefs.current[index - 1].focus();
        }
        break;

      case 'ArrowUp':
        if (inputRefs.current[index - 3]) {
          inputRefs.current[index - 3].focus();
        }
        break;

      case 'ArrowDown':
        if (inputRefs.current[index + 3]) {
          inputRefs.current[index + 3].focus();
        }
        break;
      default:
        break;
    }
  }


  return (
    <div className='main-container'>

      {/* FOR DESKTOPVIEW */}
      <div className='game-container'>

        <div className='music-div'>
          {music ? <IoMusicalNotes color='white' fontSize={'25px'} onClick={handleMusicIcon} /> : <IoMusicalNotesOutline color='white' fontSize={'25px'} onClick={handleMusicIcon} />}
        </div>

        <div className='title_and_time_container'>
          <h2 className='game-title'>Total Twist</h2>
          <div className='time_container'>
            <span>Time :</span>
            <span>&nbsp;{timeLeft}</span>
          </div>
        </div>

        <div className='box-container'>
          <div className='first-row'>
            <div className={`numBox numBoxSum ${ColumnFirstIsGlow ? `numBoxSumGlow` : ''}`}>{firstRowFirstNum}</div>
            <div className={`numBox numBoxSum ${ColumnSecondIsGlow ? `numBoxSumGlow` : ''}`}>{firstRowSecondNum}</div>
            <div className={`numBox numBoxSum ${ColumnThirdIsGlow ? `numBoxSumGlow` : ''}`}>{firstRowThirdNum}</div>
          </div>

          <div className='second-row'>
            <div className={`numBox numBoxSum ${RowFirstIsGlow ? `numBoxSumGlow` : ''}`}>{secondRowFirstNum}</div>
            <input ref={(el) => (inputRefs.current[0] = el)} className='numBox' autoComplete='off' name='box1' value={boxValues.box1} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 0)} onWheel={(e) => handleWheel(e, 0)} onFocus={() => handleFocus('box1', 0)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <input ref={(el) => (inputRefs.current[1] = el)} className='numBox' autoComplete='off' name='box2' value={boxValues.box2} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 1)} onWheel={(e) => handleWheel(e, 1)} onFocus={() => handleFocus('box2', 1)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <input ref={(el) => (inputRefs.current[2] = el)} className='numBox' autoComplete='off' name='box3' value={boxValues.box3} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 2)} onWheel={(e) => handleWheel(e, 2)} onFocus={() => handleFocus('box3', 2)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <div className={`numBox numBoxSum ${RowFirstIsGlow ? `numBoxSumGlow` : ''}`}>{secondRowFirstNum}</div>
          </div>

          <div className='third-row'>
            <div className={`numBox numBoxSum ${RowSecondIsGlow ? `numBoxSumGlow` : ''}`}>{thirdRowFirstNum}</div>
            <input ref={(el) => (inputRefs.current[3] = el)} className='numBox' autoComplete='off' name='box4' value={boxValues.box4} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 3)} onWheel={(e) => handleWheel(e, 3)} onFocus={() => handleFocus('box4', 3)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <input ref={(el) => (inputRefs.current[4] = el)} className='numBox' autoComplete='off' name='box5' value={boxValues.box5} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 4)} onWheel={(e) => handleWheel(e, 4)} onFocus={() => handleFocus('box5', 4)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <input ref={(el) => (inputRefs.current[5] = el)} className='numBox' autoComplete='off' name='box6' value={boxValues.box6} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 5)} onWheel={(e) => handleWheel(e, 5)} onFocus={() => handleFocus('box6', 5)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <div className={`numBox numBoxSum ${RowSecondIsGlow ? `numBoxSumGlow` : ''}`}>{thirdRowFirstNum}</div>
          </div>

          <div className='fourth-row'>
            <div className={`numBox numBoxSum ${RowThirdIsGlow ? `numBoxSumGlow` : ''}`}>{fourthRowFirstNum}</div>
            <input ref={(el) => (inputRefs.current[6] = el)} className='numBox' autoComplete='off' name='box7' value={boxValues.box7} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 6)} onWheel={(e) => handleWheel(e, 6)} onFocus={() => handleFocus('box7', 6)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <input ref={(el) => (inputRefs.current[7] = el)} className='numBox' autoComplete='off' name='box8' value={boxValues.box8} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 7)} onWheel={(e) => handleWheel(e, 7)} onFocus={() => handleFocus('box8', 7)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <input ref={(el) => (inputRefs.current[8] = el)} className='numBox' autoComplete='off' name='box9' value={boxValues.box9} onChange={handleBoxChange} onKeyDown={(e) => handleKeyDown(e, 8)} onWheel={(e) => handleWheel(e, 8)} onFocus={() => handleFocus('box9', 8)} readOnly={isMobileDevice} disabled={timeLeft === 60 || timeLeft === 0} />
            <div className={`numBox numBoxSum ${RowThirdIsGlow ? `numBoxSumGlow` : ''}`}>{fourthRowFirstNum}</div>
          </div>

          <div className='fifth-row'>
            <div className={`numBox numBoxSum ${ColumnFirstIsGlow ? `numBoxSumGlow` : ''}`}>{firstRowFirstNum}</div>
            <div className={`numBox numBoxSum ${ColumnSecondIsGlow ? `numBoxSumGlow` : ''}`}>{firstRowSecondNum}</div>
            <div className={`numBox numBoxSum ${ColumnThirdIsGlow ? `numBoxSumGlow` : ''}`}>{firstRowThirdNum}</div>
          </div>

        </div>

        {/* MESSAGE */}
        {winning ? <h3 className='message'>Great!!! You won the game.</h3> : <h3 className='message'>{winning === false && timeLeft === 0 ? `Time's Up` : 'Find the numbers'}</h3>}

        {/* VIRTUAL KEYBOARD */}
        <div className='keyboard-container'>

          <span className='kb-icon'><MdKeyboard color='#ffffff' fontSize={'50px'} /></span>

          <div className='number-container'>
            {keys.map((key) => <button key={key.index} className='numbers' onClick={() => handleKeyClick(key.label)} disabled={timeLeft === 60 || timeLeft === 0}>{key.label}</button>)}
          </div>
        </div>

        {timeLeft === 0 || winning ? <button class="button button3" onClick={handlePlayAgain}>Play Again</button> : <button class="button button3" onClick={handleStart}>{isTimerRunning ? 'Reset' : 'Start'}</button>}
      </div>
    </div>
  )
}
